import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

// login routing
const AdminPage = Loadable(lazy(() => import('views/adminPage')));
const UsersManagePage = Loadable(lazy(() => import('views/adminPage/UsersManagePage')));
const AdminRoutes = {
  path: 'admin',
  element: (
    <>
      <MainLayout />
    </>
  ),
  children: [
    {
      path: '',
      element: <AdminPage />,
      children: [
        {
          path: 'users',
          element: <UsersManagePage />
        }
      ]
    }
  ]
};

export default AdminRoutes;
