import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import NavSection from './NavSection';

// assets
import { IconMenu2 } from '@tabler/icons-react';
import traffiQurelogo from '../../../assets/images/traffiQurelogo.png';
// import { Container } from '@mui/system';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const location = useLocation();
  const currentUrl = location.pathname;

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        {(currentUrl.includes('map') ||
          currentUrl.includes('document') ||
          currentUrl.includes('performance') ||
          currentUrl.includes('admin')) && (
          <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              onClick={handleLeftDrawerToggle}
              color="inherit"
            >
              <IconMenu2 stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        )}
        <Box component="img" src={traffiQurelogo} alt="QureTraffic Logo" sx={{ height: 45, marginInline: 2 }} />
      </Box>

      {/* header Navigation */}
      <NavSection />

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      {!currentUrl.includes('admin') && <NotificationSection />}
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
