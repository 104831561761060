import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';

function NavSection() {
  const location = useLocation();
  const { pilot } = useParams(); // pilot will be "fdot-pilot", "mdot-pilot", or "cranberry-pilot"
  const validPilots = ['fdot-pilot', 'mdot-pilot', 'cranberry-pilot', 'txdot-pilot'];

  // Define base NAV_ITEMS
  const NAV_ITEMS = [];

  if (validPilots.includes(pilot)) {
    NAV_ITEMS.push({ label: 'Documentation', path: '/' + pilot + '/document/userguide' });
    NAV_ITEMS.push({ label: 'Map', path: '/' + pilot + '/map' });
    NAV_ITEMS.push({ label: 'Reports', path: '/' + pilot + '/reports' });
  }

  // Conditionally add Performance tab if the pilot is "fdot-pilot"
  if (pilot === 'fdot-pilot' || pilot === 'txdot-pilot') {
    NAV_ITEMS.push({ label: 'Performance', path: '/' + pilot + '/performance' });
  }

  // Conditionally add Performance tab if the pilot is "fdot-pilot"
  if (pilot === 'fdot-pilot' || pilot === 'txdot-pilot') {
    NAV_ITEMS.push({ label: 'Download', path: '/' + pilot + '/download' });
  }

  // Function to check if the current path matches the button's path
  const isCurrentPage = (path) => location.pathname === path;

  return (
    <Grid container spacing={2}>
      {NAV_ITEMS.map((item) => (
        <Grid item key={item.path}>
          <Button
            component={Link}
            to={item.path}
            variant="text"
            sx={{
              color: 'black',
              backgroundColor: isCurrentPage(item.path) ? '#f0f0f0' : 'transparent',
              '&:hover': { backgroundColor: '#e0e0e0' } // Optional: Add hover styling
            }}
          >
            {item.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default NavSection;
