import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, CircularProgress } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import cranberrySpeedData from '../../../../../../Dataset_Cranberry/spd.json';
import tsmoSpeedData from '../../../../../../Dataset_TSMO/spd.json';
import { fetchData } from '../../../../../../utils/fetchData';

const prepareSFChartData = (realTimeData, predictedData, tmcId) => {
  const dataset = [];
  if (!realTimeData?.data || !predictedData?.data) return dataset;

  const realTimeEntries = realTimeData.data[tmcId] || {};
  const predictedEntries = predictedData.data[tmcId] || {};

  // Create a map to merge observed and predicted data
  const dataMap = new Map();

  Object.entries(realTimeEntries).forEach(([time, value]) => {
    dataMap.set(time, { observed: parseFloat(value), predicted: null });
  });

  Object.entries(predictedEntries).forEach(([time, value]) => {
    if (dataMap.has(time)) {
      dataMap.get(time).predicted = parseFloat(value);
    } else {
      dataMap.set(time, { observed: null, predicted: parseFloat(value) });
    }
  });

  // Populate the dataset using sorted map entries
  Array.from(dataMap.keys())
    .sort((a, b) => new Date(a) - new Date(b))
    .forEach((time, index) => {
      const { observed, predicted } = dataMap.get(time);

      dataset.push({
        x: new Date(time),
        observed: index == 7 ? predicted : observed,
        predicted
      });
    });

  return dataset;
};

const prepareChartData = (data, tmcId, mapCenter) => {
  const dataset = [];

  const tmcData = data[tmcId];
  if (!tmcData) return dataset; // Handle case where tmcId is not found

  const allKeys = new Set([
    ...Object.keys(tmcData.pre),
    ...Object.keys(tmcData.pro),
    ...(mapCenter !== 'sf' && mapCenter !== 'tsmo' ? Object.keys(tmcData['05'] || {}) : []),
    ...(mapCenter !== 'sf' && mapCenter !== 'tsmo' ? Object.keys(tmcData['25'] || {}) : []),
    ...(mapCenter !== 'sf' && mapCenter !== 'tsmo' ? Object.keys(tmcData['75'] || {}) : []),
    ...(mapCenter !== 'sf' && mapCenter !== 'tsmo' ? Object.keys(tmcData['95'] || {}) : [])
  ]);

  const sortedKeys = Array.from(allKeys).sort((a, b) => parseInt(a) - parseInt(b));

  sortedKeys.forEach((time) => {
    if (mapCenter === 'sf' || mapCenter === 'tsmo') {
      // Push data without intervals
      dataset.push({
        x: parseInt(time),
        observed: parseFloat(tmcData.pre[time]) || null,
        predicted: parseFloat(tmcData.pro[time]) || null
      });
    } else {
      // Push data with intervals
      dataset.push({
        x: parseInt(time),
        observed: parseFloat(tmcData.pre[time]) || null,
        predicted: parseFloat(tmcData.pro[time]) || null,
        interval05: parseFloat(tmcData['05']?.[time]) || null,
        interval25: parseFloat(tmcData['25']?.[time]) || null,
        interval75: parseFloat(tmcData['75']?.[time]) || null,
        interval95: parseFloat(tmcData['95']?.[time]) || null
      });
    }
  });

  return dataset;
};

export default function SpeedChart() {
  const { selectedTmcId, mapCenter, lastUpdateTime } = useSelector((state) => state.map);
  const [chartData, setChartData] = useState([]);
  const [realTimeSpeedData, setRealTimeSpeedData] = useState({});
  const [predictedSpeedData, setPredictedSpeedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
  const boxRef = useRef(null);

  useEffect(() => {
    if (isLoading) return;
    if (mapCenter === 'sf') {
      setChartData(prepareSFChartData(realTimeSpeedData, predictedSpeedData, selectedTmcId));
    } else {
      let tmcId = selectedTmcId;
      const speedData = mapCenter === 'tsmo' ? tsmoSpeedData : cranberrySpeedData;

      if (!speedData[tmcId]) {
        tmcId = mapCenter === 'tsmo' ? '110+05026' : '104+04542';
      }

      setChartData(prepareChartData(speedData, tmcId, mapCenter));
    }
  }, [selectedTmcId, mapCenter, isLoading]);

  const fetchLastSpeedData = async () => {
    setIsLoading(true);
    try {
      const predData = await fetchData('pkl-data/prediction/predicted_speed');
      const realData = await fetchData('pkl-data/real_time/subset_spd');
      setPredictedSpeedData(predData);
      setRealTimeSpeedData(realData);
    } catch (error) {
      console.error('Error fetching last update time:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to fetch the last update time from the API
  React.useEffect(() => {
    if (mapCenter == 'sf') {
      fetchLastSpeedData();
    }
  }, []);

  // Effect to update the last update time conditionally based on difference with current time
  React.useEffect(() => {
    const checkAndUpdateLastUpdateTime = () => {
      const current = new Date();
      const differenceInMinutes = (current - lastUpdateTime) / 60000;

      if (differenceInMinutes >= 5) {
        // If the difference is 5 minutes or more, fetch the last update time
        if (mapCenter == 'sf') {
          fetchLastSpeedData();
        }
      }
    };

    // Check and update immediately, then every 1 minute
    checkAndUpdateLastUpdateTime();
    const interval = setInterval(checkAndUpdateLastUpdateTime, 60000);

    return () => clearInterval(interval);
  }, [lastUpdateTime]);

  React.useEffect(() => {
    const handleResize = () => {
      if (boxRef.current) {
        const { width, height } = boxRef.current.getBoundingClientRect();
        setChartSize({ width, height: height - 50 }); // Adjust height for padding/margins
      }
    };

    // Initial size setting
    handleResize();

    // Attach resize observer
    const resizeObserver = new ResizeObserver(handleResize);
    if (boxRef.current) resizeObserver.observe(boxRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      {isLoading ? ( // Show loading indicator when data is being fetched
        <Box
          sx={{
            textAlign: 'center',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Loading Chart...
          </Typography>
          <CircularProgress color="primary" />
        </Box>
      ) : mapCenter === 'sf' ? (
        <Box ref={boxRef} sx={{ width: '100%', height: '400px', position: 'relative' }}>
          <Typography variant="h5" align="center" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
            Speed Prediction for TMC {selectedTmcId || 'N/A'}
          </Typography>
          {chartSize.width > 0 && chartSize.height > 0 && (
            <LineChart
              dataset={chartData}
              xAxis={[
                {
                  dataKey: 'x',
                  label: 'Time',
                  scaleType: 'time',
                  valueFormatter: (value) => {
                    const date = new Date(value); // Convert value to Date object
                    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format to hh:mm
                  }
                }
              ]}
              yAxis={[{ label: 'Speed (Mph)' }]}
              series={[
                { dataKey: 'observed', label: 'Observed', color: 'red' },
                { dataKey: 'predicted', label: 'Prediction', color: '#0077b6' }
              ]}
              width={chartSize.width}
              height={chartSize.height}
            />
          )}
        </Box>
      ) : (
        <Box ref={boxRef} sx={{ width: '100%', height: '400px', position: 'relative' }}>
          <Typography variant="h5" align="center" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
            Speed Prediction for TMC {selectedTmcId || 'N/A'}
          </Typography>
          {chartSize.width > 0 && chartSize.height > 0 && (
            <LineChart
              dataset={chartData}
              xAxis={[{ dataKey: 'x', label: 'Time (Min)' }]}
              yAxis={[{ label: 'Speed (Mph)' }]}
              series={[
                { dataKey: 'observed', label: 'Observed', color: 'red' },
                { dataKey: 'predicted', label: 'Prediction', color: '#0077b6' }
              ]}
              intervals={[{ style: 'area', color: 'series-color' }]}
              interval={[
                {
                  0: { style: 'bars', color: '#333' }, // Optional styling for intervals
                  1: { style: 'bars', color: '#333' }
                }
              ]}
              width={chartSize.width}
              height={chartSize.height}
            />
          )}
        </Box>
      )}
    </>
  );
}

