import * as React from 'react';
import { Container, Typography, Stack, Grid, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import TrafficPrediction from './TrafficPredictionSection';
import RecommendAction from './TrafficPredictionSection/RecommendAction';
import { format } from 'date-fns';
import { fetchData } from '../../../../utils/fetchData';
import EarlyAnomalyAlert from './TrafficPredictionSection/EarlyAnomalyAlert';

function Predictions() {
  const { pilot } = useParams(); // Extract the "pilot" parameter from the URL
  const [lastUpdateTime, setLastUpdateTime] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(new Date());

  // Effect to update the current time every second
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchLastUpdateTime = async () => {
    try {
      const data = await fetchData('last-update-time');
      const newUpdateTime = new Date(data.last_update);
      setLastUpdateTime(newUpdateTime);
    } catch (error) {
      console.error('Error fetching last update time:', error);
    }
  };

  // Helper function to check if an update is needed
  const needsUpdate = (lastUpdateTime) => {
    if (!lastUpdateTime) return true; // Update if no last update time exists
    const current = new Date();
    const differenceInMinutes = (current - lastUpdateTime) / 60000;
    return differenceInMinutes >= 5;
  };

  // Effect to fetch the last update time on mount
  React.useEffect(() => {
    fetchLastUpdateTime();
  }, []);

  // Effect to periodically check and fetch the last update time
  React.useEffect(() => {
    const checkAndUpdateLastUpdateTime = () => {
      if (needsUpdate(lastUpdateTime)) {
        fetchLastUpdateTime();
      }
    };

    // Initial check and set up periodic checks
    checkAndUpdateLastUpdateTime();
    const interval = setInterval(checkAndUpdateLastUpdateTime, 20000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [lastUpdateTime]);

  return (
    <Container disableGutters sx={{ height: '100vh', overflow: 'hidden' }}>
      <Box
        sx={{
          height: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none' // For Chrome, Edge, and Safari
          }
        }}
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Typography component={'span'} variant="h1" color="primary.main">
            Predictions
          </Typography>
          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              <Typography component={'span'} variant="h5" color="primary.800">
                Current time
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component={'span'} variant="h5" color="primary.800">
                {format(currentTime, 'yyyy-MM-dd HH:mm:ss')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component={'span'} variant="h5" color="primary.800">
                Last update
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography component={'span'} variant="h5" color="primary.800">
                {format(lastUpdateTime, 'yyyy-MM-dd HH:mm:ss')}
              </Typography>
            </Grid>
          </Grid>
          {pilot === 'fdot-pilot' && <EarlyAnomalyAlert />}
          <RecommendAction />
          <TrafficPrediction />
        </Stack>
      </Box>
    </Container>
  );
}

export default Predictions;
