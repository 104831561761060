import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Alert, Box } from '@mui/material';
import { fetchData } from '../../../../../../utils/fetchData';
import sfRoadData from '../../../../../../Dataset_SF/analyzed_roads/analyzed_roads.json';

export default function EarlyAnomalyAlert() {
  const { lastUpdateTime } = useSelector((state) => state.map);
  const [anomalyMessage, setAnomalyMessage] = useState('');
  const [predictedAnomalyData, setPredictedAnomalyData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchLastAnomalyData = async () => {
    setIsLoading(true);
    try {
      const predData = await fetchData('pkl-data/prediction/predicted_anomaly_label');
      setPredictedAnomalyData(predData);
    } catch (error) {
      console.error('Error fetching last update time:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch anomaly data when the component is mounted
  useEffect(() => {
    fetchLastAnomalyData();
  }, []);

  // Periodically update the anomaly message
  useEffect(() => {
    if (!predictedAnomalyData || isLoading) return;

    // Update the message every minute
    const interval = setInterval(() => {
      setAnomalyMessage(generateAnomalyMessage(predictedAnomalyData));
    }, 60000);

    // Generate the message immediately when data is loaded
    setAnomalyMessage(generateAnomalyMessage(predictedAnomalyData));
    return () => clearInterval(interval); // Clear interval on unmount
  }, [predictedAnomalyData, isLoading]);

  // Re-fetch data every 5 minutes if necessary
  useEffect(() => {
    const checkAndUpdateLastUpdateTime = () => {
      const current = new Date();
      const differenceInMinutes = (current - lastUpdateTime) / 60000;

      if (differenceInMinutes >= 5) {
        fetchLastAnomalyData();
      }
    };

    const interval = setInterval(checkAndUpdateLastUpdateTime, 60000);
    return () => clearInterval(interval);
  }, [lastUpdateTime]);

  const generateAnomalyMessage = (anomalyData) => {
    const anomalies = [];

    if (!anomalyData?.data) {
      return 'No anomalies detected.';
    }

    // Map TMC data for quick lookup
    const tmcDataMap = sfRoadData.features.reduce((acc, feature) => {
      acc[feature.properties.tmc] = feature.properties;
      return acc;
    }, {});

    const currentTime = new Date();

    // Iterate through each TMC ID in the anomaly data
    Object.entries(anomalyData.data).forEach(([tmcId, timestamps]) => {
      // Sort timestamps to determine the earliest anomaly
      const sortedTimestamps = Object.keys(timestamps).sort((a, b) => new Date(a) - new Date(b));

      for (let i = 0; i < sortedTimestamps.length; i++) {
        const timeKey = sortedTimestamps[i];
        const value = timestamps[timeKey];

        // Check if the value is greater than 0 (indicating an anomaly)
        if (value > 0) {
          const anomalyTime = new Date(timeKey);
          const timeDifferenceInMinutes = Math.round((anomalyTime - currentTime) / 60000);

          // Skip anomalies in the past
          if (timeDifferenceInMinutes <= 0) {
            continue;
          }

          // Lookup TMC data
          const tmcData = tmcDataMap[tmcId];
          if (tmcData) {
            const { road, direction, description } = tmcData;
            anomalies.push(`• An anomaly is detected on ${road} ${direction} ${description} in ${timeDifferenceInMinutes} minutes.`);
          } else {
            // Fallback message if TMC data is not found
            anomalies.push(`• An anomaly is detected on an unknown road (TMC: ${tmcId}) in ${timeDifferenceInMinutes} minutes.`);
          }
          break; // Stop after finding the first anomaly for this TMC ID
        }
      }
    });

    return anomalies.length > 0
      ? anomalies.join('\n') // Combine messages with line breaks
      : 'No anomalies detected.';
  };

  return (
    <>
      <Typography component={'span'} variant="h3" sx={{ mb: 2 }}>
        Early Anomaly Alert
      </Typography>

      <Box
        sx={{
          maxHeight: 200,
          overflowY: 'auto'
        }}
      >
        <Alert severity={anomalyMessage === 'No anomalies detected.' ? 'info' : 'error'} sx={{ whiteSpace: 'pre-line' }}>
          {anomalyMessage}
        </Alert>
      </Box>
    </>
  );
}
