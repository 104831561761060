import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import mapReducer from './mapReducer';
import userReducer from './userReducer';
import notificationReducer from './notificationReducer';
import performanceReducer from './performanceReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  map: mapReducer,
  user: userReducer,
  notification: notificationReducer,
  performance: performanceReducer
});

export default reducer;
