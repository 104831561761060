// action - state management
import * as actionTypes from './actions';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - map reducer
export const SET_MAP_CENTER = '@map/SET_MAP_CENTER';
export const SET_DISPLAY_OPTIONS = '@map/SET_DISPLAY_OPTIONS';
export const SET_FORECASTING_HORIZON = '@map/SET_FORECASTING_HORIZON';
export const SET_SELECTED_TMC = 'SET_SELECTED_TMC';
export const SET_SELECTED_METRIC = 'SET_SELECTED_METRIC';

export const setMapCenter = (center) => ({
  type: actionTypes.SET_MAP_CENTER,
  payload: center
});

export const setDisplayOptions = (options) => ({
  type: actionTypes.SET_DISPLAY_OPTIONS,
  payload: options
});

export const setForecastingHorizon = (horizon) => ({
  type: SET_FORECASTING_HORIZON,
  payload: horizon
});

export const setSelectedTmc = (tmcId) => ({
  type: SET_SELECTED_TMC,
  payload: tmcId
});

export const setSelectedMetric = (metric) => ({
  type: SET_SELECTED_METRIC,
  payload: metric
});

// action - user reducer
export const SET_USER_DATA = '@user/SET_USER_DATA';
export const CLEAR_USER_DATA = '@user/CLEAR_USER_DATA';
export const UPDATE_USER_DATA = '@user/UPDATE_USER_DATA';

export const setUser = (userData) => ({
  type: 'SET_USER_DATA',
  payload: userData
});

export const clearUser = () => ({
  type: 'CLEAR_USER_DATA'
});

export const updateUserData = (newData) => ({
  type: UPDATE_USER_DATA,
  payload: newData
});

// action - notification reducer
export const ADD_NOTIFICATION = '@notification/ADD_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = '@notification/CLEAR_NOTIFICATIONS';

export const addNotification = (notification) => ({
  type: actionTypes.ADD_NOTIFICATION,
  payload: notification
});

export const clearNotifications = () => ({
  type: actionTypes.CLEAR_NOTIFICATIONS
});

// action - performance reducer
export const SET_PERFORMANCE_TMC = 'SET_PERFORMANCE_TMC';
export const setPerformanceTMC = (tmcId) => ({
  type: SET_PERFORMANCE_TMC,
  payload: tmcId
});
