// action - state management
import * as actionTypes from './actions';

const initialStatesByPilot = {
  'fdot-pilot': {
    mapCenter: 'sf',
    displayOptions: {
      incidentBlocks: true,
      rcrsIncidents: false,
      mdotIncidents: false,
      fdotIncidents: true,
      wazeAlerts: false
    },
    forecastingHorizon: 0,
    selectedTmcId: '102P06208',
    selectedMetric: 'Speed'
  },
  'mdot-pilot': {
    mapCenter: 'tsmo',
    displayOptions: {
      incidentBlocks: true,
      rcrsIncidents: false,
      mdotIncidents: true,
      fdotIncidents: false,
      wazeAlerts: true
    },
    forecastingHorizon: 0,
    selectedTmcId: '110P15271',
    selectedMetric: 'Speed'
  },
  'cranberry-pilot': {
    mapCenter: 'cranberry',
    displayOptions: {
      incidentBlocks: true,
      rcrsIncidents: true,
      mdotIncidents: false,
      fdotIncidents: false,
      wazeAlerts: true
    },
    forecastingHorizon: 0,
    selectedTmcId: '104+04542',
    selectedMetric: 'Speed'
  },
  'txdot-pilot': {
    mapCenter: 'tx',
    displayOptions: {
      incidentBlocks: false,
      rcrsIncidents: false,
      mdotIncidents: false,
      fdotIncidents: false,
      wazeAlerts: false
    },
    forecastingHorizon: 0,
    selectedTmcId: '112P04160',
    selectedMetric: ''
  }
};

// Extract pilot from URL
const pilot = window.location.pathname.split('/')[1];

// Get initial state based on the pilot
export const initialState = initialStatesByPilot[pilot] || {
  mapCenter: 'sf', // Default state if pilot is invalid or not found
  displayOptions: {
    incidentBlocks: true,
    rcrsIncidents: false,
    mdotIncidents: false,
    fdotIncidents: true,
    wazeAlerts: false
  },
  forecastingHorizon: 0,
  selectedTmcId: '102P06208',
  selectedMetric: 'Speed'
};

// ==============================|| MAP REDUCER ||============================== //

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MAP_CENTER:
      return { ...state, mapCenter: action.payload };
    case actionTypes.SET_DISPLAY_OPTIONS:
      return { ...state, displayOptions: action.payload };
    case actionTypes.SET_FORECASTING_HORIZON:
      return { ...state, forecastingHorizon: action.payload };
    case actionTypes.SET_SELECTED_TMC:
      return { ...state, selectedTmcId: action.payload };
    case actionTypes.SET_SELECTED_METRIC:
      return { ...state, selectedMetric: action.payload };
    default:
      return state;
  }
};

export default mapReducer;
