// Extract pilot from URL
const pilot = window.location.pathname.split('/')[1];

export const fetchData = async (url) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  try {
    const response = await fetch(baseUrl + pilot + '/' + url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
  }
};
