import { useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';

export default function RecommendAction() {
  const { mapCenter } = useSelector((state) => state.map);

  if (mapCenter === 'sf' || mapCenter === 'tsmo') {
    return null; // Hide the component if mapCenter is SF
  }

  return (
    <>
      <Typography component={'span'} variant="h3">
        Recommend Action
      </Typography>
      <Box sx={{ borderRadius: 1, bgcolor: '#EBF5FF', p: 1 }}>
        <Typography component={'span'} variant="body1" color="#0066CC">
          No signal plan is recommended
        </Typography>
      </Box>
    </>
  );
}
