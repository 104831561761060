import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setSelectedMetric } from 'store/actions';

// material-ui
import { Typography, Tabs, Tab, Box } from '@mui/material';
import SpeedChart from './SpeedChart';
import AnomalyChart from './AnomalyChart';
import IncidentChart from './IncidentChart';
import AnomalyProbChart from './AnomalyProbChart';
import IncidentProbChart from './IncidentProbChart';
import { styled } from '@mui/system';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
      style={{ height: '100%', overflowY: 'auto', padding: '16px' }} // Make tab panel scrollable
    >
      {value === index && (
        <Box>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

// Styled Tabs Component to allow wrapping if overflow and position underline correctly
const WrappedTabs = styled(Tabs)(() => ({
  '& .MuiTabs-flexContainer': {
    flexWrap: 'wrap' // Allow tabs to wrap into multiple lines
  },
  '& .MuiTab-root': {
    minWidth: '100px',
    flex: '1 0 auto'
  },
  '& .MuiTabs-indicator': {
    bottom: '0' // Ensure the underline (indicator) is positioned directly below the tabs
  }
}));

export default function TrafficPrediction() {
  const dispatch = useDispatch();
  const { mapCenter, selectedMetric } = useSelector((state) => state.map);

  // Determine which tabs to render based on mapCenter
  const tabs = [{ label: 'Speed', component: <SpeedChart /> }];

  if (mapCenter === 'tsmo' || mapCenter === 'sf') {
    tabs.push(
      {
        label: 'Anomaly',
        component: (
          <>
            <Box sx={{ marginBottom: '50px' }}>
              <AnomalyChart />
            </Box>
            <Box>
              <AnomalyProbChart />
            </Box>
          </>
        )
      },
      {
        label: 'Incident',
        component: (
          <>
            <Box sx={{ marginBottom: '50px' }}>
              <IncidentChart />
            </Box>
            <Box>
              <IncidentProbChart />
            </Box>
          </>
        )
      }
    );
  }

  const metricIndexMap = tabs.reduce((map, tab, index) => {
    map[tab.label] = index;
    return map;
  }, {});

  const [value, setValue] = React.useState(metricIndexMap[selectedMetric] || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setSelectedMetric(tabs[newValue].label));
  };

  return (
    <>
      <Box sx={{ height: '150vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Typography component={'span'} variant="h3" sx={{ padding: '16px', flexShrink: 0 }}>
          Traffic predictions
        </Typography>
        <Box sx={{ bgcolor: 'background.paper', width: '100%', flexShrink: 0 }}>
          <WrappedTabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { display: 'none' } }} // Styling for indicator
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </WrappedTabs>
        </Box>
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={value} index={index}>
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </>
  );
}
