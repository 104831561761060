import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, CircularProgress } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import tsmoAnomalyData from '../../../../../../Dataset_TSMO/anomaly_prob.json';
import { fetchData } from '../../../../../../utils/fetchData';

const prepareSFChartData = (predictedData, tmcId) => {
  const dataset = [];

  if (!predictedData?.data) return dataset; // Handle case where data is not available

  const predictedEntries = predictedData.data[tmcId] || {};

  // Prepare dataset for LineChart input
  Object.entries(predictedEntries).forEach(([time, value]) => {
    dataset.push({ x: new Date(time), predicted: parseFloat(value) });
  });

  return dataset;
};

const prepareChartData = (data, tmcId) => {
  const chartData = [];

  const tmcData = data[tmcId];
  if (!tmcData) return chartData;

  const allKeys = new Set([...Object.keys(tmcData.pre), ...Object.keys(tmcData.pro)]);
  const sortedKeys = Array.from(allKeys).sort((a, b) => parseInt(a) - parseInt(b));

  // Iterate through sorted keys and add data points conditionally
  sortedKeys.forEach((time) => {
    const observedValue = tmcData.pre[time] !== undefined ? parseFloat(tmcData.pre[time]) : null;
    const predictedValue = tmcData.pro[time] !== undefined ? parseFloat(tmcData.pro[time]) : null;

    if (observedValue !== null || predictedValue !== null) {
      chartData.push({
        x: time,
        observed: observedValue,
        predicted: predictedValue
      });
    }
  });

  return chartData;
};

export default function AnomalyProbChart() {
  const { selectedTmcId, mapCenter, lastUpdateTime } = useSelector((state) => state.map);
  const [predictedAnomalyProbData, setPredictedAnomalyProbData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
  const boxRef = useRef(null);

  useEffect(() => {
    if (isLoading) return;
    if (mapCenter == 'sf') {
      setChartData(prepareSFChartData(predictedAnomalyProbData, selectedTmcId));
    } else {
      let tmcId = selectedTmcId;
      // Conditionally select the appropriate anomaly data based on mapCenter
      const anomalyData = tsmoAnomalyData;

      // Use default TMC ID if none is selected or if the selected TMC ID is not found
      if (!anomalyData[tmcId]) {
        tmcId = '110+05026';
      }

      setChartData(prepareChartData(anomalyData, tmcId));
    }
  }, [selectedTmcId, mapCenter, isLoading]);

  const fetchLastAnomalyProbData = async () => {
    setIsLoading(true);
    try {
      const predData = await fetchData('pkl-data/prediction/predicted_anomaly_probability');
      setPredictedAnomalyProbData(predData);
    } catch (error) {
      console.error('Error fetching last update time:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to fetch the last update time from the API
  React.useEffect(() => {
    if (mapCenter == 'sf') {
      fetchLastAnomalyProbData();
    }
  }, []);

  // Effect to update the last update time conditionally based on difference with current time
  React.useEffect(() => {
    const checkAndUpdateLastUpdateTime = () => {
      const current = new Date();
      const differenceInMinutes = (current - lastUpdateTime) / 60000;

      if (differenceInMinutes >= 5) {
        // If the difference is 5 minutes or more, fetch the last update time
        if (mapCenter == 'sf') {
          fetchLastAnomalyProbData();
        }
      }
    };

    // Check and update immediately, then every 1 minute
    checkAndUpdateLastUpdateTime();
    const interval = setInterval(checkAndUpdateLastUpdateTime, 60000);

    return () => clearInterval(interval);
  }, [lastUpdateTime]);

  React.useEffect(() => {
    const handleResize = () => {
      if (boxRef.current) {
        const { width, height } = boxRef.current.getBoundingClientRect();
        setChartSize({ width, height: height - 50 }); // Adjust height for padding/margins
      }
    };

    // Initial size setting
    handleResize();

    // Attach resize observer
    const resizeObserver = new ResizeObserver(handleResize);
    if (boxRef.current) resizeObserver.observe(boxRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            textAlign: 'center',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Loading Chart...
          </Typography>
          <CircularProgress color="primary" />
        </Box>
      ) : mapCenter === 'sf' ? (
        <Box ref={boxRef} sx={{ width: '100%', height: '400px', position: 'relative' }}>
          <Typography variant="h5" align="center" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
            Anomaly Probability Prediction for TMC {selectedTmcId || 'N/A'}
          </Typography>
          {chartSize.width > 0 && chartSize.height > 0 && (
            <LineChart
              dataset={chartData}
              xAxis={[
                {
                  dataKey: 'x',
                  label: 'Time',
                  scaleType: 'time',
                  valueFormatter: (value) => {
                    const date = new Date(value); // Convert value to Date object
                    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format to hh:mm
                  }
                }
              ]}
              yAxis={[{ label: 'Probability', format: '0.0' }]}
              series={[{ dataKey: 'predicted', label: 'Prediction Probability', color: '#0077b6' }]}
              width={chartSize.width}
              height={chartSize.height}
              intervals={[{ style: 'area', color: 'series-color' }]}
              interval={[
                {
                  0: { style: 'bars', color: '#333' }, // Optional styling for intervals
                  1: { style: 'bars', color: '#333' }
                }
              ]}
              title={`Anomaly Prediction for TMC ${selectedTmcId || 'N/A'}`}
            />
          )}
        </Box>
      ) : (
        <Box ref={boxRef} sx={{ width: '100%', height: '400px', position: 'relative' }}>
          <Typography variant="h5" align="center" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
            Incident Probability Prediction for TMC {selectedTmcId || 'N/A'}
          </Typography>
          {chartSize.width > 0 && chartSize.height > 0 && (
            <LineChart
              dataset={chartData}
              xAxis={[{ dataKey: 'x', label: 'Time (Min)' }]}
              yAxis={[
                {
                  label: 'Probability',
                  format: '0.0'
                }
              ]}
              width={chartSize.width}
              height={chartSize.height}
              series={[
                { dataKey: 'observed', label: 'Observed', color: 'red' },
                { dataKey: 'predicted', label: 'Prediction', color: '#0077b6' }
              ]}
              intervals={[{ style: 'area', color: 'series-color' }]}
              interval={[
                {
                  0: { style: 'bars', color: '#333' }, // Optional styling for intervals
                  1: { style: 'bars', color: '#333' }
                }
              ]}
            />
          )}
        </Box>
      )}
    </>
  );
}
