// action - state management
import * as actionTypes from './actions';

export const initialState = {
  peroformanceTmcId: '102P04124'
};

// ==============================|| PERFORMANCE REDUCER ||============================== //

const performanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PERFORMANCE_TMC:
      return { ...state, peroformanceTmcId: action.payload };
    default:
      return state;
  }
};

export default performanceReducer;
