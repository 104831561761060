import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Stack,
  Box,
  TextField,
  Button,
  Autocomplete,
  Card,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { ScatterChart } from '@mui/x-charts/ScatterChart';
import { useSelector } from 'react-redux';
import axios from 'axios';
import sfRoadData from '../../../../Dataset_SF/analyzed_roads/analyzed_roads.json';

function Controls() {
  const performanceTmcId = useSelector((state) => state.performance.peroformanceTmcId);
  const [selectedTmcId, setSelectedTmcId] = useState(performanceTmcId);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [timeSteps, setTimeSteps] = useState([5]);
  const [responseData, setResponseData] = useState(null);
  const [scatterData, setScatterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { pilot } = useParams();

  const timeStepOptions = ['5', '10', '15', '20', '25', '30', 'all'];
  const tmcOptions = sfRoadData.features.map((feature) => feature.properties.tmc);

  useEffect(() => {
    setSelectedTmcId(performanceTmcId);
  }, [performanceTmcId]);

  const validateInputs = () => {
    if (!selectedTmcId || !startDate || !endDate || timeSteps.length === 0) {
      setError('All fields are required. Please provide TMC ID, date range, and time steps.');
      return false;
    }

    // Check if the start date is before or equal to the end date
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start > end) {
      setError('Start date must be before or equal to the end date.');
      return false;
    }

    setError('');
    return true;
  };

  // Utility to get yesterday's date in YYYY-MM-DD format
  const getYesterday = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Format as YYYY-MM-DD in local time
    const year = yesterday.getFullYear();
    const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(yesterday.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const fetchData = async () => {
    if (!validateInputs()) return;

    setLoading(true);
    setResponseData(null);
    setMessage(null);
    const requestData = {
      link_id: selectedTmcId,
      start_date: startDate,
      end_date: getYesterday(),
      time_step: timeSteps.join(',')
    };

    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await axios.post(baseUrl + pilot + '/performance-data', requestData);
      const { mae, rmse, scatter_data } = response.data.data.speed;
      const incidentMetrics = response.data.data.incident;
      const anomalyMetrics = response.data.data.anomaly;
      if (!response.data.data.speed) {
        setMessage('No preformance data for ' + selectedTmcId + 'during this time period');
      }
      setResponseData({
        speed: { mae, rmse },
        incident: { ...incidentMetrics },
        anomaly: { ...anomalyMetrics }
      });

      const scatterPoints = scatter_data.map((item) => ({
        x: item[`${selectedTmcId}_x`],
        y: item[`${selectedTmcId}_y`]
      }));
      setScatterData(scatterPoints);
    } catch (error) {
      setMessage('No preformance data for ' + selectedTmcId + 'during this time period');
      console.error('Error fetching performance data:', error);
    } finally {
      setLoading(false);
    }
  };

  const chartSetting = {
    yAxis: [
      {
        label: 'Predicted Speed'
      }
    ],
    xAxis: [
      {
        label: 'Observed Speed'
      }
    ],
    width: 450,
    height: 400
  };

  return (
    <Container disableGutters sx={{ height: '100vh', overflow: 'hidden' }}>
      <Box
        sx={{
          height: '100%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none' // For Chrome, Edge, and Safari
          }
        }}
      >
        <Stack spacing={2} sx={{ width: { sm: '100%', md: '100%' } }}>
          <Typography component="span" variant="h1" color="primary.main">
            Performance
          </Typography>
          <Typography component="span" variant="h3">
            Select TMC ID
          </Typography>
          <Autocomplete
            options={tmcOptions}
            value={selectedTmcId}
            onChange={(event, newValue) => setSelectedTmcId(newValue)}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
          <Typography component="span" variant="h3">
            Select Data Range
          </Typography>
          <Box>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: { max: getYesterday() }
              }}
              sx={{ width: '48%', marginRight: '4%' }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                inputProps: { max: getYesterday() }
              }}
              sx={{ width: '48%' }}
            />
          </Box>
          <Typography component="span" variant="h3">
            Select Time Step
          </Typography>
          <Autocomplete
            options={timeStepOptions}
            value={timeSteps}
            onChange={(event, newValue) => setTimeSteps(newValue ? [newValue] : [])} // Ensure only one value is selected
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={fetchData}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Loading...' : 'Check Performance'}
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
          {message && <Alert severity="info">{message}</Alert>}
          {responseData && responseData.speed && !loading && (
            <>
              <Divider />
              <Box sx={{ height: '200vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <Typography component="span" variant="h2" color="#0066CC">
                  Speed Prediction Performance
                </Typography>
                <TableContainer component={Card} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Metric</TableCell>
                        <TableCell align="right">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Mean Absolute Error (MAE)</TableCell>
                        <TableCell align="right">{responseData.speed.mae?.toFixed(2)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Root Mean Squared Error (RMSE)</TableCell>
                        <TableCell align="right">{responseData.speed.rmse?.toFixed(2)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {scatterData.length > 0 && (
                  <Box mt={2}>
                    <Typography component="span" variant="h3" color="black">
                      {`Scatter Plot of Observed vs Predicted Speed for ${timeSteps.includes('all') ? 'all' : timeSteps.join(',')} time step`}
                    </Typography>
                    <ScatterChart
                      series={[
                        {
                          data: scatterData
                        }
                      ]}
                      grid={{
                        lineWidth: 1,
                        color: '#ddd'
                      }}
                      axis={{
                        x: {
                          min: 0,
                          max: Math.max(...scatterData.map((point) => point.x))
                        },
                        y: {
                          min: 0,
                          max: Math.max(...scatterData.map((point) => point.y))
                        }
                      }}
                      {...chartSetting}
                    />
                  </Box>
                )}
                <br />
                <Divider />
                <br />
                <Typography component="span" variant="h2" color="#0066CC">
                  Incident Prediction Performance
                </Typography>
                <TableContainer component={Card} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Metric</TableCell>
                        <TableCell align="right">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Precision</TableCell>
                        <TableCell align="right">{responseData.incident.precision}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Recall</TableCell>
                        <TableCell align="right">{responseData.incident.recall?.toFixed(2)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>F1 score</TableCell>
                        <TableCell align="right">{responseData.incident.f1?.toFixed(2)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Detection rate</TableCell>
                        <TableCell align="right">{responseData.incident.detection_rate}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>False Alarm Rate:</TableCell>
                        <TableCell align="right">{responseData.incident.false_alarm_rate}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider />
                <br />
                <Typography component="span" variant="h2" color="#0066CC">
                  Anomaly Prediction Performance
                </Typography>
                <TableContainer component={Card} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Metric</TableCell>
                        <TableCell align="right">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Precision</TableCell>
                        <TableCell align="right">{responseData.anomaly.precision}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Recall</TableCell>
                        <TableCell align="right">{responseData.anomaly.recall?.toFixed(2)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>F1 score</TableCell>
                        <TableCell align="right">{responseData.anomaly.f1?.toFixed(2)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Detection rate</TableCell>
                        <TableCell align="right">{responseData.anomaly.detection_rate}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>False Alarm Rate:</TableCell>
                        <TableCell align="right">{responseData.anomaly.false_alarm_rate}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
        </Stack>
      </Box>
    </Container>
  );
}

export default Controls;
