import { lazy } from 'react';
import { Navigate, useParams } from 'react-router-dom';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/authenticationPage/AuthLogin')));
const AuthRegister = Loadable(lazy(() => import('views/authenticationPage/AuthRegister')));
const AuthForgetPW = Loadable(lazy(() => import('views/authenticationPage/AuthForgetPW')));
const AuthResetPW = Loadable(lazy(() => import('views/authenticationPage/AuthResetPW')));

function RedirectToValidPilot() {
  const { pilot } = useParams();
  const validPilots = ['fdot-pilot', 'mdot-pilot', 'cranberry-pilot', 'txdot-pilot', 'admin'];

  if (!validPilots.includes(pilot)) {
    // Redirect to fdot-pilot/login if the pilot parameter is invalid
    return <Navigate to="/fdot-pilot/login" replace />;
  }

  return null; // Render nothing if the pilot is valid
}

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: ':pilot/',
  element: (
    <>
      <RedirectToValidPilot />
      <MinimalLayout />
    </>
  ),
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'forget_pw',
      element: <AuthForgetPW />
    },
    {
      path: 'reset_pw',
      element: <AuthResetPW />
    }
  ]
};

export default AuthenticationRoutes;
